<template>
  <div class="install">
    <div class="content">
      <div class="header">
        <span
          >为保证课件内的视频正常播放，请您安装浏览器插件：Microsoft
          SilverLight</span
        >
        <img
          class="head_img"
          src="@/assets/images/pic-silverlight-logo@2x.png"
          alt="海亮教育"
          title="海亮教育"
        />
      </div>
      <div class="section">
        <div class="title">1、如何安装？</div>
        <div class="text">
          根据电脑系统选择下载版本，下载完成后直接安装即可。
        </div>
        <div class="install-i">
          <div
            class="item mac"
            @click="uoloadWindow"
            @mouseover="mouseOver('mac')"
            @mouseleave="mouseLeave('mac')"
            :class="[type == 'mac' ? 'active' : 'normal']"
          >
            <img
              class="head-img"
              :class="[type != 'mac' ? 'showMac' : 'hideMac']"
              src="@/assets/images/icon_mac@2x.png"
              alt="海亮教育"
              title="海亮教育"
            />
            <img
              :class="[type == 'mac' ? 'showMac' : 'hideMac']"
              class="head-img"
              src="@/assets/images/icon_mac_hover@2x.png"
              alt="海亮教育"
              title="海亮教育"
            />
            <div class="item-text">Mac 版下载</div>
          </div>
          <div
            class="item window"
            @click="uploadMac"
            @mouseover="mouseOver('window')"
            @mouseleave="mouseLeave('window')"
            :class="[type == 'window' ? 'active' : 'normal']"
          >
            <img
              class="head-img"
              :class="[type != 'window' ? 'showWindow' : 'hideWindow']"
              src="@/assets/images/icon_win@2x.png"
              alt="海亮教育"
              title="海亮教育"
            />
            <img
              :class="[type == 'window' ? 'showWindow' : 'hideWindow']"
              class="head-img"
              src="@/assets/images/icon_win_hover@2x.png"
              alt="海亮教育"
              title="海亮教育"
            />
            <div class="item-text">Windows版下载</div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="title">2、安装后课件视频依旧播放异常</div>
        <div class="tips">1）建议使用以下浏览器：chrome、火狐、safari；</div>
        <div class="tips">
          2）个人上传课件中嵌入视频单个需小于50M，仅支持MP4格式。可通过视频编辑工具压缩或转换格式，大视频建议通过超链接方式处理。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { windowOpen } from "@/utils";
export default {
  name: "InstallTip",
  components: {},
  data() {
    return {
      type: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    uploadMac() {
      let url = "http://go.microsoft.com/fwlink/?LinkID=229321";
      windowOpen(url, "_blank");
    },
    uoloadWindow() {
      let url = "http://go.microsoft.com/fwlink/?LinkID=229322";
      windowOpen(url, "_blank");
    },
    mouseLeave() {
      this.type = "";
    },
    mouseOver(type) {
      this.type = type;
    },
  },
};
</script>

<style lang="less" scoped>
.install {
  min-height: calc(100vh - 164px);
  margin-top: 38px;
  background: rgba(255, 255, 255, 1);
  .content {
    border-radius: 4px;
    padding: 34px 48px;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 17px;
      span {
        font-size: 16px;
        color: rgba(100, 108, 149, 1);
        line-height: 22px;
        margin-right: 29px;
      }
      .head_img {
        width: 202px;
      }
    }
    .section {
      margin-bottom: 70px;
      .title {
        font-size: 15px;
        font-weight: bold;
        color: rgba(55, 64, 81, 1);
        line-height: 34px;
        letter-spacing: 1px;
        margin-bottom: 16px;
      }
      .text {
        font-size: 14px;
        color: rgba(68, 68, 68, 1);
        line-height: 36px;
        letter-spacing: 1px;
        margin-bottom: 40px;
      }
      .tips {
        font-size: 14px;
        color: rgba(68, 68, 68, 1);
        line-height: 36px;
        letter-spacing: 1px;
      }
      .install-i {
        padding-left: 152px;
        display: flex;
        .item {
          width: 178px;
          background: rgba(249, 249, 249, 1);
          border-radius: 9px;
          text-align: center;
          padding-top: 16px;
          padding-bottom: 14px;
          cursor: pointer;
          .head-img {
            width: 40px;
            height: 40px;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 4px;
          }
          .item-text {
            font-size: 16px;
            font-weight: bold;
            line-height: 34px;
            letter-spacing: 1px;
          }
        }
        .item:hover {
          color: rgba(255, 255, 255, 1);
          background: rgba(73, 124, 240, 1);
          // width: 180px;
          -webkit-transition: all 0.2s cubic-bezier(0, 0, 0, 0.48);
          -moz-transition: all 0.2s ease;
          transition: all 0.2s;
          border: 1px solid rgba(73, 124, 240, 0.49);
        }
        .mac {
          margin-right: 100px;
        }
        .normal {
          color: rgba(73, 124, 240, 1);
          background: rgba(249, 249, 249, 1);
          border: 1px solid rgba(73, 124, 240, 0.49);
        }
        .active {
          color: rgba(255, 255, 255, 1);
          background: rgba(73, 124, 240, 1);
        }
        .showMac {
          display: inherit;
        }
        .hideMac {
          display: none;
        }
        .showWindow {
          display: inherit;
        }
        .hideWindow {
          display: none;
        }
        .nor-img {
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
